import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'stripe',
        path: `/stripe`,
        component: React.lazy(() => import('views/stripe-views/index')),
        meta: {
            blankLayout: true
        }
    },
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.unconfirmed.charger',
        path: `${APP_PREFIX_PATH}/dashboards/unconfirmedCharger`,
        component: React.lazy(() => import('views/app-views/dashboards/unconfirmedCharger')),
    },
    {
        key: 'dashboard.charging.station',
        path: `${APP_PREFIX_PATH}/dashboards/cs`,
        component: React.lazy(() => import('views/app-views/dashboards/chargingStation')),
    },
    {
        key: 'dashboard.charging.station.view',
        path: `${APP_PREFIX_PATH}/cs/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/chargingStation/view')),
    },
    {
        key: 'dashboard.charging.station.view',
        path: `${APP_PREFIX_PATH}/cs/:id/:transactionId`,
        component: React.lazy(() => import('views/app-views/dashboards/chargingStation/transaction')),
    },
    {
        key: 'stripe.payment.gateway',
        path: `/stripe/stripePaymentForm/frame/:paymentUrl`,
        component: React.lazy(() => import('views/stripe-views/stripe/stripePaymentForm/frame')),
    },
    {
        key: 'stripe',
        path: `/stripe/chargingStation`,
        component: React.lazy(() => import('views/stripe-views/stripe')),
        // meta: {
        //     blankLayout: true
        // }
    },
    {
        key: 'no-permission',
        path: `${APP_PREFIX_PATH}/permissions`,
        component: React.lazy(() => import('views/app-views/permissions/no-permission')),
    },
]