import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import AuthService from 'services/AuthService';
import {doc, getDoc, onSnapshot} from 'firebase/firestore';
import { db } from 'auth/FirebaseAuth';


export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
	prodUser: null,
	listenProdUser: false,
	idToken: null,
}

export const signIn = createAsyncThunk('auth/login',async (data, { rejectWithValue }) => {
	const { email, password } = data
	try {
		const response = await FirebaseService.signInEmailRequest(email, password)
		if (response.user) {
			const token = response.user.uid;
			const docRef = doc(db, "prodUsers", token);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				const idToken = await response.user.getIdToken(true);
				localStorage.setItem(AUTH_TOKEN, token);
				const tokenUid = {
					idToken: idToken,
					token: token
				}
				return tokenUid;
			} 
			else {
				return rejectWithValue('You have no permission to access this system.');
			}
		} 
		else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		console.log(err.message);
		return rejectWithValue(err.message || 'Error')
	}
})

export const fetchProdUser = (uid) => {
	return (dispatch) => {
	  dispatch(fetchProdUserStart());
	  const unsubscribe = onSnapshot(doc(db, "prodUsers", uid), (doc) => {
        dispatch(fetchProdUserSuccess(doc.data()));
		//console.log(doc.data());
		return doc.data();
      }, (error) => {
		dispatch(fetchProdUserFailure(error.message));
	  });
  
	  return () => {
		unsubscribe();
		dispatch(unsubscribeProdUser());
	  };
	};
};

export const checkProdUser = createAsyncThunk('auth/prodUser',async (uid, { rejectWithValue }) => {
	try {
		const docRef = doc(db, "prodUsers", uid);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			localStorage.setItem(AUTH_TOKEN, uid);
			return uid;
		} 
		else {
			return rejectWithValue('You have no permission to access this system.');
		}

	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})

export const signUp = createAsyncThunk('auth/register',async (data, { rejectWithValue }) => {
	const { email, password } = data
	console.log('Auth Slice: ' + JSON.stringify(data));
	try {
		const response = await FirebaseService.createUserRequest({email, password})
		console.log('Register UUID: ' + response.user.uid);
		return response.user.uid;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signOut = createAsyncThunk('auth/logout',async () => {
	try {
		const response = await FirebaseService.signOutRequest()
		localStorage.removeItem(AUTH_TOKEN);
		return response.data;
	}
	catch (err) {
		console.log(err.response.data.message);
		//return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (_, { rejectWithValue }) => {
    try {
		const response = await AuthService.loginInOAuth()
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signInWithFacebook = createAsyncThunk('auth/signInWithFacebook', async (_, { rejectWithValue }) => {
    try {
		const response = await AuthService.loginInOAuth()
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			console.log('authenticated');
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			console.log('showAuthMessage');
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			console.log('hideAuthMessage');
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			console.log('signout success');
			state.loading = false
			state.token = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			console.log('Loading');
			state.loading = true
		},
		signInSuccess: (state, action) => {
			console.log('Sign in success');
			state.loading = false
			state.token = action.payload
		},
		setFetchProdUser(state, action) {
			state.listenProdUser = action.payload;
		},
		fetchProdUserStart(state) {
			state.loading = true
		},
		fetchProdUserSuccess(state, action) {
			console.log('Listening prodUser document...');
			state.loading = false
			state.prodUser = action.payload
		},
		fetchProdUserFailure(state, action) {
			console.log('Failed to listen prodUser');
			state.loading = false
		},
		unsubscribeProdUser(state) {
			console.log('Clear ProdUser.');
			state.prodUser = null;
		},
		updateIdToken(state, action) {
			//console.log('Update Id token: ' + action.payload);
			state.idToken = action.payload;
		},
		clearAuthData(state) {
			console.log('Clear Auth Data');
			state.loading = false
			state.prodUser = null
			state.token = null
			state.idToken = null
			state.redirect = '/'
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				console.log('Sign in pending');
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				const {idToken, token } = action.payload;
				console.log('Sign in fulfill');
				// console.log('idToken: ' + idToken);
				// console.log('uid:' + token);
				state.loading = false;
				state.redirect = '/';
				state.token = token;
				state.idToken = idToken;
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signOut.pending, (state) => {
				console.log('signOut pending');
				state.loading = true
				// state.token = null
				// state.redirect = '/'
			})
			.addCase(signOut.fulfilled, (state) => {
				console.log('signOut fulfilled');
				state.loading = false
				state.prodUser = null
				state.token = null
				state.idToken = null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				console.log('signOut reject');
				state.loading = false
				state.token = null
				state.idToken = null
				state.redirect = '/'

			})
			.addCase(checkProdUser.pending, (state) => {
				console.log('Checking prodUser');
				state.loading = true
			})
			.addCase(checkProdUser.fulfilled, (state, action) => {
				console.log('Valid ProdUser');
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(checkProdUser.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = true
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
				state.idToken = action.payload
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithGoogle.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithGoogle.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
				state.idToken = action.payload
			})
			.addCase(signInWithGoogle.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithFacebook.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithFacebook.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
				state.idToken = action.payload
			})
			.addCase(signInWithFacebook.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const { 
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess,
	fetchProdUserStart,
	fetchProdUserSuccess,
	fetchProdUserFailure,
	unsubscribeProdUser,
	setFetchProdUser,
	updateIdToken,
	clearAuthData
} = authSlice.actions

export default authSlice.reducer