const FirebaseConfig = {
  apiKey: "AIzaSyBS0Jg96nfh5gQK0qUP518hMX0h2tgaoOo",
  authDomain: "",
  databaseURL: "https://v-default-rtdb.firebaseio.com",
  projectId: "ocpp16-8899d",
  storageBucket: "v.appspot.com",
  messagingSenderId: "484859945311"
};

export default FirebaseConfig

