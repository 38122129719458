const dev = {
  API_ENDPOINT_URL: '/api/dev',
  STRIPE_PK_TEST: 'pk_test_51NXP0FDo5Duz2o7HC87IvWn0GyAEX7ujTAQjaasUNR4ejblZSBxp7AC7J6m5IazLRGUbkgD6Z5VQtRhx1V4dQl2300KzYfSDUv',
  STRIPE_SK_TEST: 'sk_test_51NXP0FDo5Duz2o7HFYQS8QubszZcbvZOds3veJb6K9vHYTMlp0Fvxn80FbjMHkCcUv6FO5FXGuw5Rlflj7VnuiP700PoosXhBh',
  STRIPE_PK: 'pk_live_51NXP0FDo5Duz2o7HqHwIcMm9wjSTF8S4jpdBbKZR13YIwjDvKRo3kGU06chG5XS9X6X8t9q2InX5s7PgCA3xktkq00wdRRXxyh',
  STRIPE_SK: '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',
  STRIPE_URL_TEST: 'https://codeverse.pro:3033/',
  STRIPE_URL: 'https://codeverse.pro:3033/',
};

const prod = {
  API_ENDPOINT_URL: '/api/prod',
  STRIPE_PK: 'pk_live_51NXP0FDo5Duz2o7HqHwIcMm9wjSTF8S4jpdBbKZR13YIwjDvKRo3kGU06chG5XS9X6X8t9q2InX5s7PgCA3xktkq00wdRRXxyh',
  STRIPE_SK: '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',
};

const test = {
  API_ENDPOINT_URL: '/api/test'
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
