import React, { useEffect } from 'react';
import { onBlankLayout, onUserLayout } from 'store/slices/themeSlice';
import { useDispatch } from 'react-redux';

const AppRoute = ({ component: Component, routeKey, blankLayout, userLayout, ...props }) => {
	const dispatch = useDispatch()

	useEffect(() => {
		const isBlank = blankLayout ? true : false
		dispatch(onBlankLayout(isBlank))

	}, [blankLayout])

	// useEffect(() => {
	// 	const isUser = userLayout ? true : false
	// 	dispatch(onUserLayout(isUser))

	// }, [userLayout])
	
	return (
		<Component {...props} />
	)
}

export default AppRoute