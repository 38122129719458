import { initializeApp } from "firebase/app"
import { getFirestore } from 'firebase/firestore';
import { 
	getAuth, 
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	signOut,
	signInWithPopup,
	GoogleAuthProvider,
	FacebookAuthProvider,
	deleteUser,
} from 'firebase/auth'
import { getStorage, ref, uploadBytesResumable} from "firebase/storage";

import firebaseConfig from 'configs/FirebaseConfig';

const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);
const storageProfileImgRef = ref(storage, 'UserProfilePic/prod01@eviebikes.com');
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();

export {
	db,
	auth,
	storage,
	storageProfileImgRef,
	googleAuthProvider,
	GoogleAuthProvider,
	facebookAuthProvider,
	FacebookAuthProvider,
	signInWithEmailAndPassword,
	signOut,
	signInWithPopup,
	createUserWithEmailAndPassword,
	deleteUser
};