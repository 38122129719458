import { createSlice } from '@reduxjs/toolkit';
import {collection, onSnapshot, query, orderBy, doc, getDoc, getDocs, where} from 'firebase/firestore';
import { db } from 'auth/FirebaseAuth';

export const initialState = {
	getSelectedORLoading: true,
	selectedORModel: null,
}

export const listenORModel = (id) => {
	return (dispatch) => {
	  dispatch(listenORModelStart());
	  const unsubscribe = onSnapshot(doc(db, "organizations", id), (docSnapshot) => {
		var data;
		if (docSnapshot.exists()) {
			data = docSnapshot.data();
			dispatch(listenORModelSuccess(data));
		} 
		else {
			data = null;
			dispatch(listenORModelSuccess(data));
		}
		return data;
      }, (error) => {
		console.log('Error');
		dispatch(listenORModelFailure(error.message));
	  });
  
	  return () => {
		console.log('UNsub');
		unsubscribe();
		dispatch(unsubscribeORModel());
	  };
	};
};

export const orSlice = createSlice({
	name: 'or',
	initialState,
	reducers: {

		listenORModelStart(state, action) {
			state.getSelectedORLoading = true
		},

		listenORModelSuccess(state, action) {
			state.getSelectedORLoading = false;
			state.selectedORModel = action.payload;
		},
		listenORModelFailure(state, action) {
			console.log('Failed to get or...');
			state.getSelectedORLoading = true;
		},
		unsubscribeORModel(state) {
			console.log('unsub or.');
			state.selectedORModel = null;
		},
	},
})

export const { 

	listenORModelStart,
	listenORModelSuccess,
	listenORModelFailure,
	unsubscribeORModel,

} = orSlice.actions

export default orSlice.reducer