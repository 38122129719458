import { createSlice } from '@reduxjs/toolkit';
import {collection, onSnapshot, query, orderBy, doc, getDoc, getDocs, where} from 'firebase/firestore';
import { db } from 'auth/FirebaseAuth';

export const initialState = {
	getIotModuleListLoading: true,
    listenIotModuleList: false,
    iotModuleList: [],

	getSelectedIotModuleLoading: true,
	selectedIotModule: null,

	getLockModuleListLoading: true,
    listenLockModuleList: false,
    lockModuleList: [],

	getSelectedLockModuleLoading: true,
	selectedLockModule: null,

	getFrameListLoading: true,
	listenFrameList: false,
	frameList:[],

	getSelectedFrameLoading: true,
	selectedFrame: null,

	getHandlebarDisplayListLoading: true,
	listenHandlebarDisplayList: false,
	handlebarDisplayList:[],

	getSelectedHandlebarDisplayLoading: true,
	selectedHandlebarDisplay: null,

	getMotorListLoading: true,
	listenMotorList: false,
	motorList: [],

	getSelectedMotorLoading: true,
	selectedMotor: null,

	getControllerListLoading: true,
	listenControllerList: false,
	controllerList: [],

	getSelectedControllerLoading: true,
	selectedController: null,

	getBatteryListLoading: true,
	listenBatteryList: false,
	batteryList: [],

	getSelectedBatteryLoading: true,
	selectedBattery: null,

	getTorqueSensorListLoading: true,
	listenTorqueSensorList: false,
	torqueSensorList: [],

	getSelectedTorqueSensorLoading: true,
	selectedTorqueSensor: null,

}

export const fetchIotModuleList = () => {
	return (dispatch) => {
	  dispatch(fetchIotModuleListStart());
      console.log('Listening IotModule list...');
	  const unsubscribe = onSnapshot(query(collection(db, "inventory/iotModules/serialNumber"), where("type", "==", "01"), orderBy('deviceIMEI')), (querySnapshot) => {
        const iotModuleList = querySnapshot.docs.map(doc => doc.data());
        //console.log(iotModuleList);
        dispatch(fetchIotModuleListSuccess(iotModuleList));
		return iotModuleList;
      }, (error) => {
		console.log(error.message);
		dispatch(fetchIotModuleListFailure(error.message));
	  });
  
	  return () => {
		console.log('UNsub');
		unsubscribe();
		dispatch(unsubscribeIotModuleList());
	  };
	};
};

export const getIotModule = (id) => {
	return (dispatch) => {
		dispatch(getSelectedIotModuleStart());
		const documentRef = doc(db, "inventory/iotModules/serialNumber", id); // specify the collection and document ID
		getDoc(documentRef).then((docSnapshot) => {
			if (docSnapshot.exists()) { // check if the document exists
				const data = docSnapshot.data(); // get the document data
				dispatch(getSelectedIotModuleSuccess(data));
				//console.log(data);
				return data;
			  } else {
				dispatch(getSelectedIotModuleFailure());
				console.log("Document not found!");
			  }
		}); // get the document snapshot
	}
}

export const fetchLockModuleList = () => {
	return (dispatch) => {
	  dispatch(fetchLockModuleListStart());
      console.log('Listening LockModule list...');
	  const unsubscribe = onSnapshot(query(collection(db, "inventory/lockModules/serialNumber"), where("type", "==", "01"), orderBy('serialNumber')), (querySnapshot) => {
        const lockModuleList = querySnapshot.docs.map(doc => doc.data());
        //console.log(iotModuleList);
        dispatch(fetchLockModuleListSuccess(lockModuleList));
		return lockModuleList;
      }, (error) => {
		console.log(error.message);
		dispatch(fetchLockModuleListFailure(error.message));
	  });
  
	  return () => {
		console.log('UNsub');
		unsubscribe();
		dispatch(unsubscribeLockModuleList());
	  };
	};
};

export const getLockModule = (id) => {
	return (dispatch) => {
		dispatch(getSelectedLockModuleStart());
		const documentRef = doc(db, "inventory/lockModules/serialNumber", id); // specify the collection and document ID
		getDoc(documentRef).then((docSnapshot) => {
			if (docSnapshot.exists()) { // check if the document exists
				const data = docSnapshot.data(); // get the document data
				dispatch(getSelectedLockModuleSuccess(data));
				//console.log(data);
				return data;
			  } else {
				dispatch(getSelectedLockModuleFailure());
				console.log("Document not found!");
			  }
		}); // get the document snapshot
	}
}

export const fetchFrameList = () => {
	return (dispatch) => {
	  dispatch(fetchFrameListStart());
      console.log('Listening Frame list...');
	  const unsubscribe = onSnapshot(query(collection(db, "inventory/frame/serialNumber"), orderBy('serialNumber'), where("type", "==", "01")), (querySnapshot) => {
        const frameList = querySnapshot.docs.map(doc => doc.data());
        //console.log(frameList);
        dispatch(fetchFrameListSuccess(frameList));
		return frameList;
      }, (error) => {
		console.log(error.message);
		dispatch(fetchFrameListFailure(error.message));
	  });
  
	  return () => {
		console.log('UNsub');
		unsubscribe();
		dispatch(unsubscribeFrameList());
	  };
	};
};

export const getFrame = (id) => {
	return (dispatch) => {
		dispatch(getSelectedFrameStart());
		const documentRef = doc(db, "inventory/frame/serialNumber", id); // specify the collection and document ID
		getDoc(documentRef).then((docSnapshot) => {
			if (docSnapshot.exists()) { // check if the document exists
				const data = docSnapshot.data(); // get the document data
				dispatch(getSelectedFrameSuccess(data));
				//console.log(data);
				return data;
			  } else {
				dispatch(getSelectedFrameFailure());
				console.log("Document not found!");
			  }
		}); // get the document snapshot
	}
}

export const fetchHandlebarDisplayList = () => {
	return (dispatch) => {
	  dispatch(fetchHandlebarDisplayListStart());
      console.log('Listening HandlebarDisplay list...');
	  const unsubscribe = onSnapshot(query(collection(db, "inventory/handlebarDisplay/serialNumber"), orderBy('serialNumber'), where("type", "==", "01")), (querySnapshot) => {
        const handlebarDisplayList = querySnapshot.docs.map(doc => doc.data());
        //console.log(frameList);
        dispatch(fetchHandlebarDisplayListSuccess(handlebarDisplayList));
		return handlebarDisplayList;
      }, (error) => {
		console.log(error.message);
		dispatch(fetchHandlebarDisplayListFailure(error.message));
	  });
  
	  return () => {
		console.log('UNsub');
		unsubscribe();
		dispatch(unsubscribeHandlebarDisplayList());
	  };
	};
};

export const getHandlebarDisplay = (id) => {
	return (dispatch) => {
		dispatch(getSelectedHandlebarDisplayStart());
		const documentRef = doc(db, "inventory/handlebarDisplay/serialNumber", id); // specify the collection and document ID
		getDoc(documentRef).then((docSnapshot) => {
			if (docSnapshot.exists()) { // check if the document exists
				const data = docSnapshot.data(); // get the document data
				dispatch(getSelectedHandlebarDisplaySuccess(data));
				//console.log(data);
				return data;
			  } else {
				dispatch(getSelectedHandlebarDisplayFailure());
				console.log("Document not found!");
			  }
		}); // get the document snapshot
	}
}

export const fetchMotorList = () => {
	return (dispatch) => {
	  dispatch(fetchMotorListStart());
      console.log('Listening Motor list...');
	  const unsubscribe = onSnapshot(query(collection(db, "inventory/motor/serialNumber"), orderBy('serialNumber'), where("type", "==", "01")), (querySnapshot) => {
        const motorList = querySnapshot.docs.map(doc => doc.data());
        //console.log(motorList);
        dispatch(fetchMotorListSuccess(motorList));
		return motorList;
      }, (error) => {
		console.log(error.message);
		dispatch(fetchMotorListFailure(error.message));
	  });
  
	  return () => {
		console.log('UNsub');
		unsubscribe();
		dispatch(unsubscribeMotorList());
	  };
	};
};

export const getMotor = (id) => {
	return (dispatch) => {
		dispatch(getSelectedMotorStart());
		const documentRef = doc(db, "inventory/motor/serialNumber", id); // specify the collection and document ID
		getDoc(documentRef).then((docSnapshot) => {
			if (docSnapshot.exists()) { // check if the document exists
				const data = docSnapshot.data(); // get the document data
				dispatch(getSelectedMotorSuccess(data));
				//console.log(data);
				return data;
			  } else {
				dispatch(getSelectedMotorFailure());
				console.log("Document not found!");
			  }
		}); // get the document snapshot
	}
}

export const fetchControllerList = () => {
	return (dispatch) => {
	  dispatch(fetchControllerListStart());
      console.log('Listening Controller list...');
	  const unsubscribe = onSnapshot(query(collection(db, "inventory/controller/serialNumber"), orderBy('serialNumber'), where("type", "==", "01")), (querySnapshot) => {
        const controllerList = querySnapshot.docs.map(doc => doc.data());
        //console.log(motorList);
        dispatch(fetchControllerListSuccess(controllerList));
		return controllerList;
      }, (error) => {
		console.log(error.message);
		dispatch(fetchControllerListFailure(error.message));
	  });
  
	  return () => {
		console.log('UNsub');
		unsubscribe();
		dispatch(unsubscribeControllerList());
	  };
	};
};

export const getController = (id) => {
	return (dispatch) => {
		dispatch(getSelectedControllerStart());
		const documentRef = doc(db, "inventory/controller/serialNumber", id); // specify the collection and document ID
		getDoc(documentRef).then((docSnapshot) => {
			if (docSnapshot.exists()) { // check if the document exists
				const data = docSnapshot.data(); // get the document data
				dispatch(getSelectedControllerSuccess(data));
				//console.log(data);
				return data;
			  } else {
				dispatch(getSelectedControllerFailure());
				console.log("Document not found!");
			  }
		}); // get the document snapshot
	}
}

export const fetchBatteryList = () => {
	return (dispatch) => {
	  dispatch(fetchBatteryListStart());
      console.log('Listening Battery list...');
	  const unsubscribe = onSnapshot(query(collection(db, "inventory/battery/serialNumber"), orderBy('serialNumber'), where("type", "==", "01")), (querySnapshot) => {
        const batteryList = querySnapshot.docs.map(doc => doc.data());
        //console.log(batteryList);
        dispatch(fetchBatteryListSuccess(batteryList));
		return batteryList;
      }, (error) => {
		console.log(error.message);
		dispatch(fetchBatteryListFailure(error.message));
	  });
  
	  return () => {
		console.log('UNsub');
		unsubscribe();
		dispatch(unsubscribeBatteryList());
	  };
	};
};

export const getBattery = (id) => {
	return (dispatch) => {
		dispatch(getSelectedBatteryStart());
		const documentRef = doc(db, "inventory/battery/serialNumber", id); // specify the collection and document ID
		getDoc(documentRef).then((docSnapshot) => {
			if (docSnapshot.exists()) { // check if the document exists
				const data = docSnapshot.data(); // get the document data
				dispatch(getSelectedBatterySuccess(data));
				//console.log(data);
				return data;
			  } else {
				dispatch(getSelectedBatteryFailure());
				console.log("Document not found!");
			  }
		}); // get the document snapshot
	}
}

export const fetchTorqueSensorList = () => {
	return (dispatch) => {
	  dispatch(fetchTorqueSensorListStart());
      console.log('Listening TorqueSensor list...');
	  const unsubscribe = onSnapshot(query(collection(db, "inventory/torqueSensor/serialNumber"), orderBy('serialNumber'), where("type", "==", "01")	), (querySnapshot) => {
        const torqueSensorList = querySnapshot.docs.map(doc => doc.data());
        //console.log(batteryList);
        dispatch(fetchTorqueSensorListSuccess(torqueSensorList));
		return torqueSensorList;
      }, (error) => {
		console.log(error.message);
		dispatch(fetchTorqueSensorListFailure(error.message));
	  });
  
	  return () => {
		console.log('UNsub');
		unsubscribe();
		dispatch(unsubscribeTorqueSensorList());
	  };
	};
};

export const getTorqueSensor = (id) => {
	return (dispatch) => {
		dispatch(getSelectedTorqueSensorStart());
		const documentRef = doc(db, "inventory/torqueSensor/serialNumber", id); // specify the collection and document ID
		getDoc(documentRef).then((docSnapshot) => {
			if (docSnapshot.exists()) { // check if the document exists
				const data = docSnapshot.data(); // get the document data
				dispatch(getSelectedTorqueSensorSuccess(data));
				//console.log(data);
				return data;
			  } else {
				dispatch(getSelectedTorqueSensorFailure());
				console.log("Document not found!");
			  }
		}); // get the document snapshot
	}
}

export const componentSlice = createSlice({
	name: 'component',
	initialState,
	reducers: {
		///Listen Iot Module List
		setFetchIotModuleList(state, action) {
			state.listenIotModuleList = action.payload;
		},
		fetchIotModuleListStart(state) {
			state.getIotModuleListLoading = true
		},
		fetchIotModuleListSuccess(state, action) {
			state.getIotModuleListLoading = false
			state.iotModuleList = action.payload
		},
		fetchIotModuleListFailure(state, action) {
			console.log('Failed to listen iotModuleList');
			state.getIotModuleListLoading = false
		},
		unsubscribeIotModuleList(state) {
			console.log('Clear iotModuleList.');
			state.iotModuleList = [];
			state.listenIotModuleList = false;
		},

		///Get Iot Module Information
		getSelectedIotModuleStart(state) {
			state.getSelectedIotModuleLoading = true;
		},
		getSelectedIotModuleSuccess(state, action) {
			state.getSelectedIotModuleLoading = false;
			state.selectedIotModule = action.payload;
		},
		getSelectedIotModuleFailure(state, action) {
			console.log('Failed to get iotModule...');
			state.getSelectedIotModuleLoading = false;
		},

		///Listen Lock Module List
		setFetchLockModuleList(state, action) {
			state.listenLockModuleList = action.payload;
		},
		fetchLockModuleListStart(state) {
			state.getLockModuleListLoading = true
		},
		fetchLockModuleListSuccess(state, action) {
			state.getLockModuleListLoading = false
			state.lockModuleList = action.payload
		},
		fetchLockModuleListFailure(state, action) {
			console.log('Failed to listen lockModuleList');
			state.getLockModuleListLoading = false
		},
		unsubscribeLockModuleList(state) {
			console.log('Clear lockModuleList.');
			state.lockModuleList = [];
			state.listenLockModuleList = false;
		},

		///Get Lock Module Information
		getSelectedLockModuleStart(state) {
			state.getSelectedLockModuleLoading = true;
		},
		getSelectedLockModuleSuccess(state, action) {
			state.getSelectedLockModuleLoading = false;
			state.selectedLockModule = action.payload;
		},
		getSelectedLockModuleFailure(state, action) {
			console.log('Failed to get lockModule...');
			state.getSelectedLockModuleLoading = false;
		},

		///Listen Frame List
		setFetchFrameList(state, action) {
			state.listenFrameList = action.payload;
		},
		fetchFrameListStart(state) {
			state.getFrameListLoading = true
		},
		fetchFrameListSuccess(state, action) {
			state.getFrameListLoading = false
			state.frameList = action.payload
		},
		fetchFrameListFailure(state, action) {
			console.log('Failed to listen FrameList');
			state.getFrameListLoading = false
		},
		unsubscribeFrameList(state) {
			console.log('Clear FrameList.');
			state.frameList = [];
			state.listenFrameList = false;
		},

		///Get Frame Information
		getSelectedFrameStart(state) {
			state.getSelectedFrameLoading = true;
		},
		getSelectedFrameSuccess(state, action) {
			state.getSelectedFrameLoading = false;
			state.selectedFrame = action.payload;
		},
		getSelectedFrameFailure(state, action) {
			console.log('Failed to get frame...');
			state.getSelectedFrameLoading = false;
		},
		
		///Listen HandlebarDisplay List
		setFetchHandlebarDisplayList(state, action) {
			state.listenHandlebarDisplayList = action.payload;
		},
		fetchHandlebarDisplayListStart(state) {
			state.getHandlebarDisplayListLoading = true
		},
		fetchHandlebarDisplayListSuccess(state, action) {
			state.getHandlebarDisplayListLoading = false
			state.handlebarDisplayList = action.payload
		},
		fetchHandlebarDisplayListFailure(state, action) {
			console.log('Failed to listen HandlebarDisplayList');
			state.getHandlebarDisplayListLoading = false
		},
		unsubscribeHandlebarDisplayList(state) {
			console.log('Clear HandlebarDisplayList.');
			state.handlebarDisplayList = [];
			state.listenHandlebarDisplayList = false;
		},

		///Get HandlebarDisplay Information
		getSelectedHandlebarDisplayStart(state) {
			state.getSelectedHandlebarDisplayLoading = true;
		},
		getSelectedHandlebarDisplaySuccess(state, action) {
			state.getSelectedHandlebarDisplayLoading = false;
			state.selectedHandlebarDisplay = action.payload;
		},
		getSelectedHandlebarDisplayFailure(state, action) {
			console.log('Failed to get handlebarDisplay...');
			state.getSelectedHandlebarDisplayLoading = false;
		},

		///Listen Motor List
		setFetchMotorList(state, action) {
			state.listenMotorList = action.payload;
		},
		fetchMotorListStart(state) {
			state.getMotorListLoading = true
		},
		fetchMotorListSuccess(state, action) {
			state.getMotorListLoading = false
			state.motorList = action.payload
		},
		fetchMotorListFailure(state, action) {
			console.log('Failed to listen MotorList');
			state.getMotorListLoading = false
		},
		unsubscribeMotorList(state) {
			console.log('Clear MotorList.');
			state.motorList = [];
			state.listenMotorList = false;
		},

		///Get Motor Information
		getSelectedMotorStart(state) {
			state.getSelectedMotorLoading = true;
		},
		getSelectedMotorSuccess(state, action) {
			state.getSelectedMotorLoading = false;
			state.selectedMotor = action.payload;
		},
		getSelectedMotorFailure(state, action) {
			console.log('Failed to get motor...');
			state.getSelectedMotorLoading = false;
		},

		///Listen Controller List
		setFetchControllerList(state, action) {
			state.listenControllerList = action.payload;
		},
		fetchControllerListStart(state) {
			state.getControllerListLoading = true
		},
		fetchControllerListSuccess(state, action) {
			state.getControllerListLoading = false
			state.controllerList = action.payload
		},
		fetchControllerListFailure(state, action) {
			console.log('Failed to listen ControllerList');
			state.getControllerListLoading = false
		},
		unsubscribeControllerList(state) {
			console.log('Clear ControllerList.');
			state.controllerList = [];
			state.listenControllerList = false;
		},

		///Get Controller Information
		getSelectedControllerStart(state) {
			state.getSelectedControllerLoading = true;
		},
		getSelectedControllerSuccess(state, action) {
			state.getSelectedControllerLoading = false;
			state.selectedController = action.payload;
		},
		getSelectedControllerFailure(state, action) {
			console.log('Failed to get controller...');
			state.getSelectedControllerLoading = false;
		},

		///Listen Battery List
		setFetchBatteryList(state, action) {
			state.listenBatteryList = action.payload;
		},
		fetchBatteryListStart(state) {
			state.getBatteryListLoading = true
		},
		fetchBatteryListSuccess(state, action) {
			state.getBatteryListLoading = false
			state.batteryList = action.payload
		},
		fetchBatteryListFailure(state, action) {
			console.log('Failed to listen BatteryList');
			state.getBatteryListLoading = false
		},
		unsubscribeBatteryList(state) {
			console.log('Clear BatteryList.');
			state.batteryList = [];
			state.listenBatteryList = false;
		},

		///Get Battery Information
		getSelectedBatteryStart(state) {
			state.getSelectedBatteryLoading = true;
		},
		getSelectedBatterySuccess(state, action) {
			state.getSelectedBatteryLoading = false;
			state.selectedBattery = action.payload;
		},
		getSelectedBatteryFailure(state, action) {
			console.log('Failed to get battery...');
			state.getSelectedBatteryLoading = false;
		},

		///Listen TorqueSensor List
		setFetchTorqueSensorList(state, action) {
			state.listenTorqueSensorList = action.payload;
		},
		fetchTorqueSensorListStart(state) {
			state.getTorqueSensorListLoading = true
		},
		fetchTorqueSensorListSuccess(state, action) {
			state.getTorqueSensorListLoading = false
			state.torqueSensorList = action.payload
		},
		fetchTorqueSensorListFailure(state, action) {
			console.log('Failed to listen TorqueSensorList');
			state.getTorqueSensorListLoading = false
		},
		unsubscribeTorqueSensorList(state) {
			console.log('Clear TorqueSensorList.');
			state.torqueSensorList = [];
			state.listenTorqueSensorList = false;
		},

		///Get TorqueSensor Information
		getSelectedTorqueSensorStart(state) {
			state.getSelectedTorqueSensorLoading = true;
		},
		getSelectedTorqueSensorSuccess(state, action) {
			state.getSelectedTorqueSensorLoading = false;
			state.selectedTorqueSensor = action.payload;
		},
		getSelectedTorqueSensorFailure(state, action) {
			console.log('Failed to get torqueSensor...');
			state.getSelectedTorqueSensorLoading = false;
		},
	},
})

export const { 
	setFetchIotModuleList,
	fetchIotModuleListStart,
	fetchIotModuleListSuccess,
	fetchIotModuleListFailure,
	unsubscribeIotModuleList,

	getSelectedIotModuleStart,
	getSelectedIotModuleSuccess,
	getSelectedIotModuleFailure,

	setFetchLockModuleList,
	fetchLockModuleListStart,
	fetchLockModuleListSuccess,
	fetchLockModuleListFailure,
	unsubscribeLockModuleList,

	getSelectedLockModuleStart,
	getSelectedLockModuleSuccess,
	getSelectedLockModuleFailure,

	setFetchFrameList,
	fetchFrameListStart,
	fetchFrameListSuccess,
	fetchFrameListFailure,
	unsubscribeFrameList,

	getSelectedFrameStart,
	getSelectedFrameSuccess,
	getSelectedFrameFailure,

	setFetchHandlebarDisplayList,
	fetchHandlebarDisplayListStart,
	fetchHandlebarDisplayListSuccess,
	fetchHandlebarDisplayListFailure,
	unsubscribeHandlebarDisplayList,

	getSelectedHandlebarDisplayStart,
	getSelectedHandlebarDisplaySuccess,
	getSelectedHandlebarDisplayFailure,

	setFetchMotorList,
	fetchMotorListStart,
	fetchMotorListSuccess,
	fetchMotorListFailure,
	unsubscribeMotorList,

	getSelectedMotorStart,
	getSelectedMotorSuccess,
	getSelectedMotorFailure,
	
	setFetchControllerList,
	fetchControllerListStart,
	fetchControllerListSuccess,
	fetchControllerListFailure,
	unsubscribeControllerList,

	getSelectedControllerStart,
	getSelectedControllerSuccess,
	getSelectedControllerFailure,


	setFetchBatteryList,
	fetchBatteryListStart,
	fetchBatteryListSuccess,
	fetchBatteryListFailure,
	unsubscribeBatteryList,

	getSelectedBatteryStart,
	getSelectedBatterySuccess,
	getSelectedBatteryFailure,

	setFetchTorqueSensorList,
	fetchTorqueSensorListStart,
	fetchTorqueSensorListSuccess,
	fetchTorqueSensorListFailure,
	unsubscribeTorqueSensorList,

	getSelectedTorqueSensorStart,
	getSelectedTorqueSensorSuccess,
	getSelectedTorqueSensorFailure,
	
} = componentSlice.actions

export default componentSlice.reducer