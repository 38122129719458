import React  from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig'

const PublicRoute = () => {

	const { token } = useSelector(state => state.auth)  
	const location = useLocation();
	//console.log('PublicRoute: ' + location.pathname);
	//return token != null ? <Navigate to={AUTHENTICATED_ENTRY} /> : <Outlet/>

	if (token != null) {
		if (location.pathname === '/stripe') {
			return <Outlet/>
		}
		else {
			//return <Navigate to={AUTHENTICATED_ENTRY} />
			return <Outlet/>
		}
	}
	else {
		return <Outlet/>
	}
}

export default PublicRoute