import React, { lazy, Suspense, memo, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { ConfigProvider } from 'antd';
import Loading from 'components/shared-components/Loading';
import { lightTheme, darkTheme } from 'configs/ThemeConfig';
import { resources } from 'lang';
import useBodyClass from 'utils/hooks/useBodyClass';
import Routes from 'routes'
import { fetchProdUser, signOut, clearAuthData, updateIdToken } from 'store/slices/authSlice';
import { fetchProdUserList, setListenProdUserList } from 'store/slices/prodUserSlice';
import { fetchCSList, fetchEvieBikeOption } from 'store/slices/csSlice';
import { fetchIotModuleList, fetchLockModuleList, fetchBatteryList, fetchMotorList, fetchFrameList, fetchHandlebarDisplayList, fetchControllerList, fetchTorqueSensorList } from 'store/slices/componentSlice';
import { getAuth, setPersistence, browserSessionPersistence, onAuthStateChanged} from 'firebase/auth';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import { LOADING_INTERVAL } from 'constants/ApiConstant';

const AppLayout = lazy(() => import('./AppLayout'));
const AuthLayout = lazy(() => import('./AuthLayout'));

const Layouts = () => {
	const dispatch = useDispatch();
	const { token, listenProdUser } = useSelector(({auth}) => auth);
	const { listenProdUserList } = useSelector(({prodUser}) => prodUser);
	const { listenCSList } = useSelector(({cs}) => cs);
	const { 
		listenIotModuleList,
		listenLockModuleList,
		listenMotorList,
		listenBatteryList,
		listenFrameList,
		listenHandlebarDisplayList,
		listenControllerList,
		listenTorqueSensorList,
	 } = useSelector(({component}) => component);
	const blankLayout = useSelector(state => state.theme.blankLayout);

	//const Layout = token && !blankLayout ? AppLayout : AuthLayout;
	const Layout = AppLayout;
	//const Layout = token == null && userLayout ? UserLayout : token && !blankLayout ? AppLayout : AuthLayout;

	const locale = useSelector(state => state.theme.locale);

	const direction = useSelector(state => state.theme.direction);

	const currentTheme = useSelector(state => state.theme.currentTheme);

	const currentAppLocale = resources[locale];

	useBodyClass(`dir-${direction}`);

	const themeConfig = currentTheme === 'light' ? {...lightTheme} : {...darkTheme}


	// /// Listen to current Production User document data if listenProdUser == true;
	// useEffect(() => {
	// 	if (token != null && listenProdUser) {
	// 		const unsubscribe = dispatch(fetchProdUser(token));
	// 		return unsubscribe;
	// 	}
	// }, [dispatch, token, listenProdUser]);

	// /// Listen to Production User List data if listenProdUserList == true
	// useEffect(() => {
	// 	let timerId;
	// 	if (token != null && listenProdUserList) {
	// 		timerId = setTimeout(() => {
	// 			const unsubscribe = dispatch(fetchProdUserList());
	// 			return () => {
	// 			  unsubscribe();
	// 			};
	// 		  }, LOADING_INTERVAL);
	// 	}
	// 	return () => {
	// 		clearTimeout(timerId); // clear the timer when the component is unmounted
	// 	};
	// }, [dispatch, token, listenProdUserList]);

	/// Listen to CS List data if listenCSList == true
	useEffect(() => {
		let timerId;
		if (listenCSList) {
			timerId = setTimeout(() => {
				const unsubscribe = dispatch(fetchCSList());
				return () => {
				  unsubscribe();
				};
			  }, LOADING_INTERVAL);
		}
		return () => {
			clearTimeout(timerId); // clear the timer when the component is unmounted
		};
	}, [dispatch, listenCSList]);

	// /// Listen to Iot Module List data if listenIotModuleList == true
	// useEffect(() => {
	// 	let timerId;
	// 	if (token != null && listenIotModuleList) {
	// 		timerId = setTimeout(() => {
	// 			const unsubscribe = dispatch(fetchIotModuleList());
	// 			return () => {
	// 			  unsubscribe();
	// 			};
	// 		  }, LOADING_INTERVAL);
	// 	}
	// 	return () => {
	// 		clearTimeout(timerId); // clear the timer when the component is unmounted
	// 	};
	// }, [dispatch, token, listenIotModuleList]);

	// /// Listen to Lock Module List data if listenLockModuleList == true
	// useEffect(() => {
	// 	let timerId;
	// 	if (token != null && listenLockModuleList) {
	// 		timerId = setTimeout(() => {
	// 			const unsubscribe = dispatch(fetchLockModuleList());
	// 			return () => {
	// 			  unsubscribe();
	// 			};
	// 		  }, LOADING_INTERVAL);
	// 	}
	// 	return () => {
	// 		clearTimeout(timerId); // clear the timer when the component is unmounted
	// 	};
	// }, [dispatch, token, listenLockModuleList]);

	// /// Listen to Motor List data if listenMotorList == true
	// useEffect(() => {
	// 	let timerId;
	// 	if (token != null && listenMotorList) {
	// 		timerId = setTimeout(() => {
	// 			const unsubscribe = dispatch(fetchMotorList());
	// 			return () => {
	// 			  unsubscribe();
	// 			};
	// 		  }, LOADING_INTERVAL);
	// 	}
	// 	return () => {
	// 		clearTimeout(timerId); // clear the timer when the component is unmounted
	// 	};
	// }, [dispatch, token, listenMotorList]);

	// /// Listen to Controller List data if listenControllerList == true
	// useEffect(() => {
	// 	let timerId;
	// 	if (token != null && listenControllerList) {
	// 		timerId = setTimeout(() => {
	// 			const unsubscribe = dispatch(fetchControllerList());
	// 			return () => {
	// 			  unsubscribe();
	// 			};
	// 		  }, LOADING_INTERVAL);
	// 	}
	// 	return () => {
	// 		clearTimeout(timerId); // clear the timer when the component is unmounted
	// 	};
	// }, [dispatch, token, listenControllerList]);

	// /// Listen to Battery List data if listenBatteryList == true
	// useEffect(() => {
	// 	let timerId;
	// 	if (token != null && listenBatteryList) {
	// 		timerId = setTimeout(() => {
	// 			const unsubscribe = dispatch(fetchBatteryList());
	// 			return () => {
	// 			  unsubscribe();
	// 			};
	// 		  }, LOADING_INTERVAL);
	// 	}
	// 	return () => {
	// 		clearTimeout(timerId); // clear the timer when the component is unmounted
	// 	};
	// }, [dispatch, token, listenBatteryList]);

	// /// Listen to TorqueSensor List data if listenTorqueSensorList == true
	// useEffect(() => {
	// 	let timerId;
	// 	if (token != null && listenTorqueSensorList) {
	// 		timerId = setTimeout(() => {
	// 			const unsubscribe = dispatch(fetchTorqueSensorList());
	// 			return () => {
	// 			  unsubscribe();
	// 			};
	// 		  }, LOADING_INTERVAL);
	// 	}
	// 	return () => {
	// 		clearTimeout(timerId); // clear the timer when the component is unmounted
	// 	};
	// }, [dispatch, token, listenTorqueSensorList]);

	// /// Listen to Frame List data if listenFrameList == true
	// useEffect(() => {
	// 	let timerId;
	// 	if (token != null && listenFrameList) {
	// 		timerId = setTimeout(() => {
	// 			const unsubscribe = dispatch(fetchFrameList());
	// 			return () => {
	// 			  unsubscribe();
	// 			};
	// 		  }, LOADING_INTERVAL);
	// 	}
	// 	return () => {
	// 		clearTimeout(timerId); // clear the timer when the component is unmounted
	// 	};
	// }, [dispatch, token, listenFrameList]);

	// /// Listen to HandlebarDisplay List data if listenHandlebarDisplayList == true
	// useEffect(() => {
	// 	let timerId;
	// 	if (token != null && listenHandlebarDisplayList) {
	// 		timerId = setTimeout(() => {
	// 			const unsubscribe = dispatch(fetchHandlebarDisplayList());
	// 			return () => {
	// 			  unsubscribe();
	// 			};
	// 		  }, LOADING_INTERVAL);
	// 	}
	// 	return () => {
	// 		clearTimeout(timerId); // clear the timer when the component is unmounted
	// 	};
	// }, [dispatch, token, listenHandlebarDisplayList]);

	// /// Listen to Evie Bike List data if listenEvieBikeList == true
	// useEffect(() => {
	// 	let timerId;
	// 	if (token != null && listenEvieBikeOption) {
	// 		timerId = setTimeout(() => {
	// 			const unsubscribe = dispatch(fetchEvieBikeOption());
	// 			return () => {
	// 			  unsubscribe();
	// 			};
	// 		  }, LOADING_INTERVAL);
	// 	}
	// 	return () => {
	// 		clearTimeout(timerId); // clear the timer when the component is unmounted
	// 	};
	// }, [dispatch, token, listenEvieBikeOption]);

	// const handleAuthStateChange = (user) => {
	// 	if (user) {
	// 	  //console.log(JSON.stringify(user));	
	// 	  user.getIdTokenResult().then((idTokenResult) => {
	// 		const idToken = idTokenResult.token;
	// 		console.log(idToken);
	// 		const expirationTime = idTokenResult.expirationTime;
	// 		dispatch(updateIdToken(idToken));
	// 	  });
	// 	} else {
	// 	  dispatch(clearAuthData());
	// 	}
	// };

	// useEffect(() => {
	// 	const auth = getAuth();
	// 	const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);
	
	// 	return () => {
	// 	  // Unsubscribe the listener when the component unmounts
	// 	  unsubscribe();
	// 	};
	// }, []);

	// useEffect(() => {
	// 	const auth = getAuth();
	// 	setPersistence(auth, browserSessionPersistence)
	// 		.then(() => {
	// 			const currentUser = auth.currentUser;
	// 			console.log('getAuth: ' + JSON.stringify(currentUser));
	// 			if (!currentUser) {
	// 				localStorage.removeItem(AUTH_TOKEN);
	// 				dispatch(clearAuthData());
	// 			}
	// 			else {
	// 				currentUser.getIdTokenResult().then((idTokenResult) => {
	// 					const idToken = idTokenResult.token;
    //         			const expirationTime = idTokenResult.expirationTime;
	// 					dispatch(updateIdToken(idToken));
	// 				});
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.log(error);
	// 		});
	// }, []);

	return (
		<ConfigProvider theme={themeConfig} direction={direction} locale={currentAppLocale.antd}>
			<Suspense fallback={<Loading cover="content"/>}>
				<Layout>
					<Routes />
				</Layout>
			</Suspense>
		</ConfigProvider>
	)
}

export default memo(Layouts)