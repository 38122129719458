import { createSlice } from '@reduxjs/toolkit';
import {collection, onSnapshot, query, orderBy} from 'firebase/firestore';
import { db } from 'auth/FirebaseAuth';

export const initialState = {
	loading: true,
    listenProdUserList: false,
    prodUserList: [],
}

export const fetchProdUserList = () => {
	return (dispatch) => {
	  dispatch(fetchProdUserListStart());
	  console.log('Listening prodUser list...');
	  const unsubscribe = onSnapshot(query(collection(db, "prodUsers"), orderBy('name')), (querySnapshot) => {
		//console.log('Obtain prodUserList data');
        const prodUserList = querySnapshot.docs.map(doc => doc.data());
		//console.log(JSON.stringify(prodUserList));
        dispatch(fetchProdUserListSuccess(prodUserList));
		return prodUserList;
      }, (error) => {
		console.log('Error');
		dispatch(fetchProdUserListFailure(error.message));
	  });
  
	  return () => {
		console.log('UNsub');
		unsubscribe();
		dispatch(unsubscribeProdUserList());
	  };
	};
};


export const prodUserSlice = createSlice({
	name: 'prodUser',
	initialState,
	reducers: {
		setFetchProdUserList(state, action) {
			state.listenProdUserList = action.payload;
		},
		fetchProdUserListStart(state) {
			state.loading = true
		},
		fetchProdUserListSuccess(state, action) {
			state.loading = false
			state.prodUserList = action.payload
		},
		fetchProdUserListFailure(state, action) {
			console.log('Failed to listen prodUserList');
			state.loading = false
		},
		unsubscribeProdUserList(state) {
			console.log('Clear ProdUserList.');
			state.prodUserList = [];
			state.listenProdUserList = false;
		}
	},
})

export const { 
	fetchProdUserListStart,
	fetchProdUserListSuccess,
	fetchProdUserListFailure,
	unsubscribeProdUserList,
	setFetchProdUserList,
} = prodUserSlice.actions

export default prodUserSlice.reducer