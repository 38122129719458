import { createSlice } from '@reduxjs/toolkit';
import {collection, onSnapshot, query, orderBy, doc, getDoc, getDocs, where} from 'firebase/firestore';
import { db } from 'auth/FirebaseAuth';

export const initialState = {
	loading: true,
	csOptionLoading: true,
	getSelectedCSLoading: true,
	getPrintCSLoading: true,
    listenCSList: false,
    csList: [],
	selectedCSModel: null,
	getSelectedCSListLoading: true,
	selectedCSList:[],
	transLoading: true,
	transDetail: null,
	timeDifference: null,
	idleTimeDifference: null,
	checkingTransaction: true,
	matchTransaction: false,
}

export const fetchCSList = () => {
	return (dispatch) => {
	  dispatch(fetchCSListStart());
      console.log('Listening CS list...');
	  const unsubscribe = onSnapshot(query(collection(db, "chargers"), where("holdBy", "==", "Ms Solaris")), (querySnapshot) => {
		const csList = querySnapshot.docs.map(doc => {
			const data = doc.data();
			return data;
		});
        //console.log(evieBikeList);
        dispatch(fetchCSListSuccess(csList));
		return csList;
      }, (error) => {
		console.log('Error');
		dispatch(fetchCSListFailure(error.message));
	  });
  
	  return () => {
		console.log('UNsub');
		unsubscribe();
		dispatch(unsubscribeCSList());
	  };
	};
};

export const fetchCSTransDetail = (chargerId, transactionId) => {
	return (dispatch) => {
	  dispatch(fetchCSTransDetailStart());
	  const unsubscribe = onSnapshot(doc(db, `chargers/${chargerId}/transactions`, transactionId), (docSnapshot) => {

		var csTransDetail;
		if (docSnapshot.exists()) {
			csTransDetail = docSnapshot.data();
			console.log(JSON.stringify(csTransDetail));

			if (csTransDetail.startTime != null && csTransDetail.endTime == null) {
				// Convert Firestore timestamp to JavaScript Date object
				const startTime = csTransDetail.startTime.toDate();

				// Get the current date and time
				const currentTime = new Date();
			
				// Calculate the time difference in milliseconds
				const differenceInMillis = currentTime - startTime;

				// Convert the time difference to a formatted string (HH:mm:ss)
				const formattedTimeDifference = formatTimeDifference(differenceInMillis);
				csTransDetail.timeDifference = formattedTimeDifference;

				// Parse the string values to doubles
				const latestEnergy = parseFloat(csTransDetail.latestEnergy);
				const initialEnergy = parseFloat(csTransDetail.initialEnergy);

				const comsumedEnergy = latestEnergy - initialEnergy;
				csTransDetail.comsumedEnergy = comsumedEnergy;
				dispatch(fetchCSTransDetailSuccess(csTransDetail));
			}
			else if (csTransDetail.startTime != null && csTransDetail.endTime != null) {
				// Convert Firestore timestamp to JavaScript Date object
				const startTime = csTransDetail.startTime.toDate();

				// Get the current date and time
				const endTime = csTransDetail.endTime.toDate();
			
				// Calculate the time difference in milliseconds
				const differenceInMillis = endTime - startTime;

				// Convert the time difference to a formatted string (HH:mm:ss)
				const formattedTimeDifference = formatTimeDifference(differenceInMillis);
				csTransDetail.timeDifference = formattedTimeDifference;

				// Parse the string values to doubles
				const latestEnergy = parseFloat(csTransDetail.latestEnergy);
				const initialEnergy = parseFloat(csTransDetail.initialEnergy);

				const comsumedEnergy = latestEnergy - initialEnergy;
				csTransDetail.comsumedEnergy = comsumedEnergy;

				if (csTransDetail.idleStartTime != null && csTransDetail.idleEndTime != null) {
					// Convert Firestore timestamp to JavaScript Date object
					const idleStartTime = csTransDetail.idleStartTime.toDate();

					// Get the current date and time
					const idleEndTime = csTransDetail.idleEndTime.toDate();

					// Calculate the time difference in milliseconds
					const differenceIdleInMillis = idleEndTime - idleStartTime;

					// Convert the time difference to a formatted string (HH:mm:ss)
					const formattedIdleTimeDifference = formatTimeDifference(differenceIdleInMillis);
					csTransDetail.idleTimeDifference = formattedIdleTimeDifference;
					dispatch(fetchCSTransDetailSuccess(csTransDetail));
				}
				else {
				  dispatch(fetchCSTransDetailSuccess(csTransDetail));
				}
			}
			else {
				dispatch(fetchCSTransDetailSuccess(csTransDetail));
			}
		} 
		else {
			csTransDetail = null;
			dispatch(fetchCSTransDetailSuccess(csTransDetail));
		}
		return csTransDetail;
      }, (error) => {
		console.log('Error');
		dispatch(fetchCSTransDetailFailure(error.message));
	  });
  
	  return () => {
		console.log('UNsub');
		unsubscribe();
		dispatch(unsubscribeCSTransDetail());
	  };
	};
};

// Function to format the time difference in "00:00:00" format
const formatTimeDifference = (timeInMillis) => {
    const hours = Math.floor(timeInMillis / (1000 * 60 * 60));
    const minutes = Math.floor((timeInMillis % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeInMillis % (1000 * 60)) / 1000);

    // Pad single digits with leading zeros
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

export const getCSModel = (id) => {
	return (dispatch) => {
		dispatch(getSelectedCSStart());
		const documentRef = doc(db, "chargers", id); // specify the collection and document ID
		getDoc(documentRef).then((docSnapshot) => {
			if (docSnapshot.exists()) { // check if the document exists
				const data = docSnapshot.data(); // get the document data
				dispatch(getSelectedCSSuccess(data));
				//console.log(data);
				return data;
			  } else {
				dispatch(getSelectedCSFailure());
				console.log("Document not found!");
			  }
		}); // get the document snapshot
	}
}

export const checkTransaction = (chargerId, transactionId, email) => {
	return (dispatch) => {
		dispatch(checkTransactionStart());
		if (transactionId != null && email != null) {
			const documentRef = doc(db, `chargers/${chargerId}/transactions`, transactionId); // specify the collection and document ID
			getDoc(documentRef).then((docSnapshot) => {
				if (docSnapshot.exists()) { // check if the document exists
					const data = docSnapshot.data(); // get the document data
					console.log(data);
					if (data.email == email) {
						dispatch(checkTransactionSuccess(true));
					}
					else {
						dispatch(checkTransactionSuccess(false));
					}
					return data;
				} 
				else {
					dispatch(checkTransactionFailure());
					console.log("Document not found!");
				}
			}); 
		}// get the document snapshot
		else {
			dispatch(checkTransactionSuccess(false));
		}
	}
}

export const listenCSModel = (id) => {
	return (dispatch) => {
	  dispatch(listenCSModelStart());
	  const unsubscribe = onSnapshot(doc(db, "chargers", id), (docSnapshot) => {
		var data;
		if (docSnapshot.exists()) {
			data = docSnapshot.data();
			//console.log(JSON.stringify(data));
			dispatch(listenCSModelSuccess(data));
		} 
		else {
			data = null;
			dispatch(listenCSModelSuccess(data));
		}
		return data;
      }, (error) => {
		console.log('Error');
		dispatch(listenCSModelFailure(error.message));
	  });
  
	  return () => {
		console.log('UNsub');
		unsubscribe();
		dispatch(unsubscribeCSModel());
	  };
	};
};

export const csSlice = createSlice({
	name: 'cs',
	initialState,
	reducers: {
		///Listen Evie Bike List
		setFetchCSList(state, action) {
			state.listenCSList = action.payload;
		},
		fetchCSListStart(state) {
			state.loading = true
		},
		fetchCSListSuccess(state, action) {
			state.loading = false
			state.csList = action.payload
		},
		fetchCSListFailure(state, action) {
			console.log('Failed to listen csList');
			state.loading = false
		},
		unsubscribeCSList(state) {
			console.log('Clear csList.');
			state.csList = [];
			state.listenCSList = false;
		},

		listenCSModelStart(state, action) {
			state.getSelectedCSLoading = true
		},

		listenCSModelSuccess(state, action) {
			state.getSelectedCSLoading = false;
			state.selectedCSModel = action.payload;
		},
		listenCSModelFailure(state, action) {
			console.log('Failed to get cs...');
			state.getSelectedCSLoading = true;
		},
		unsubscribeCSModel(state) {
			console.log('Clear transDetail.');
			state.selectedCSModel = null;
		},

		fetchCSTransDetailStart(state, action) {
			state.transLoading = true
		},
		fetchCSTransDetailSuccess(state, action) {
			state.transLoading = false
			state.transDetail = action.payload
			console.log(JSON.stringify(action.payload));
		},
		fetchCSTransDetailFailure(state, action) {
			console.log('Failed to listen transDetail');
			state.transLoading = false
		},
		unsubscribeCSTransDetail(state) {
			console.log('Clear transDetail.');
			state.transDetail = null;
		},
		///Get Evie Bike Information
		getSelectedCSStart(state) {
			state.getSelectedCSLoading = true;
		},
		checkTransactionStart(state) {
			state.checkingTransaction = true;
		},
		getSelectedCSSuccess(state, action) {
			state.getSelectedCSLoading = false;
			state.selectedCSModel = action.payload;
		},
		checkTransactionSuccess(state, action) {
			state.matchTransaction = action.payload;
			state.checkingTransaction = false;
		},
		getSelectedCSFailure(state, action) {
			console.log('Failed to get cs...');
			state.getSelectedCSLoading = true;
		},
		checkTransactionFailure(state, action) {
			state.checkingTransaction = false;
		},
		/// Clear Edit Bike data
		clearEditCSData(state) {
			state.getSelectedCSLoading = true;
			state.selectedCSModel = null;
		},
	},
})

export const { 
	setFetchCSList,
	fetchCSListStart,
	fetchCSListSuccess,
	fetchCSListFailure,
	unsubscribeCSList,

	fetchCSTransDetailStart,
	fetchCSTransDetailSuccess,
	fetchCSTransDetailFailure,
	unsubscribeCSTransDetail,

	getSelectedCSStart,
	getSelectedCSSuccess,
	getSelectedCSFailure,

	checkTransactionStart,
	checkTransactionSuccess,
	checkTransactionFailure,

	listenCSModelStart,
	listenCSModelSuccess,
	listenCSModelFailure,
	unsubscribeCSModel,


	clearEditCSData
	
} = csSlice.actions

export default csSlice.reducer